@use "/src/styles/variables" as v;

.popup_container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 9980;

  &_hidden{
    display: none;
  }
}

.popup_block{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-left: 10%;
  margin-top: 40%;
  padding: 15px 0;
  width: 80%;
  height: 190px;
  background-color: white;
  border: 2px solid v.$hb-green;
  border-radius: 10px;
  //z-index: 9981;
}