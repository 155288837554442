@use "/src/styles/variables" as v;

.hb_button{
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v.$hb-pink;
  width: 160px;
  height: 50px;
  color: white;
  border: unset;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;

  &:disabled{
    cursor: unset;
    background-color: v.$hb-grey;
  }
}

.hb_button_error{
  margin-top: 5px;
  font-size: v.$font-size-sm;
  color: red;
}