$dot-block-diameter: 20px;
$dot-diameter: 14px;

.select_row{
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.select_dot_block{
  display: flex;
  justify-content: center;
  align-items: center;
  width: $dot-block-diameter;
  height: $dot-block-diameter;
  border-radius: 50%;
  background-color: grey;
}

.select_dot_active{
    width: $dot-diameter;
    height: $dot-diameter;
    border-radius: 50%;
    background-color: deeppink;
}

