.title_container{
  width: 100%;
  display: flex;
  justify-content: center;
}

.payment_description_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment_data_container{
  padding-top: 20px;
}

.and_or_container{
  margin-top: 15px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.input_title{
  margin-bottom: 10px;
  font-weight: bold;
}

.payment_method_container{
  margin-top: 20px;
}

.payment_method_title{
  font-weight: bold;
}

.button_container{
  margin-top: 30px;
}