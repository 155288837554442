@use "/src/styles/variables" as v;

.preloader_container{
  height:100%;
  aspect-ratio: 1/1;
}

@keyframes preloader_rolling {
  from {rotate: 0deg;}
  to {rotate: 360deg;}
}

.preloader_block{
  width: 100%;
  height: 100%;
  border: 4px dashed v.$hb-pink;
  border-radius: 50%;
  animation-name: preloader_rolling;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}