@use "/src/styles/variables" as v;

$input-font-size: 18px;
$input-padding-left: 5px;

.hb_input_container{
  position: relative;
  height: 50px;
}

.hb_input{
  padding-left: $input-padding-left;
  font-size: $input-font-size;
}

.hb_input_tel{
  padding-left: 26px;
  font-size: $input-font-size;
}

.tel_code{
  position: absolute;
  top: 2px;
  left: $input-padding-left;
  font-size: $input-font-size;
}

.input_error_container{
  margin-top: 5px;
  font-size: v.$font-size-sm;
  color: red;
}