$display-resolution-xxlg: 2520px;
$display-resolution-xlg: 1880px;
$display-resolution-lg: 1640px;
$display-resolution-md: 1250px;
$display-resolution-sm: 950px;
$display-resolution-xsm: 600px;

$display-resolution-desktop: 1920;
$display-resolution-mobile: 390;

$hb-pink: rgb(204, 0, 153);
$hb-grey: rgba(0, 0, 0, 0.4);
$hb-green: rgba(204, 255, 51, 1);
$hb-blue: rgba(51, 51, 255, 1);

$font-size-sm: 14px;
